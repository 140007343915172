export default {
    "搜尋名單": "搜寻名单",
    "搜尋範圍: 名字/郵件": "搜寻范围: 名字/邮件",
    "族群名稱": "族群名称",
    "請輸入族群名稱": "请输入族群名称",
    "所屬族群": "所属族群",
    "請選擇所屬族群": "请选择所属族群",
    "關閉": "关闭",
    "確定": "确定",
    "最少需要選擇幾位族群成員": "最少需要选择 %s 位族群成员",
    "最少需要選擇幾位託管人員": "最少需要选择 %s 位托管人员",
    "最多只能選擇幾位託管人員": "最多只能选择 %s 位托管人员",
    "尚未異動項目": "尚未异动项目",
    "成員種類1": "族群成员",
    "成員種類2": "托管人员",
    "可選擇幾個會友": "可选择 <b class=\"cgBase\">%s</b> 个会友",
    "請選擇加入的項目": "请选择加入的项目",
    "加入": "加入",
    "移除": "移除",
    "已選擇幾位族群成員": "已选择 <b class=\"cgBase\">%s</b> 位族群成员",
    "已選擇幾位託管人員": "已选择 <b class=\"cgBase\">%s</b> 位托管人员",
    "相關資訊": "相关资讯",
    "基本": "基本",
    "團報": "团报",
    "表單": "表单",
    "金流": "金流",
    "報名人員": "报名人员",
    "報名時間": "报名时间",
    "重設": "重设",
    "立即更新": "立即更新",
    "聚會開始時間": "聚会开始时间",
    "請選擇開始時間": "请选择开始时间",
    "首次聚會開始時間": "首次聚会开始时间",
    "聚會結束時間": "聚会结束时间",
    "請選擇結束時間": "请选择结束时间",
    "首次聚會結束時間": "首次聚会结束时间",
}
