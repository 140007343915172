export default {
    "搜尋名單": "搜尋名單",
    "搜尋範圍: 名字/郵件": "搜尋範圍: 名字/郵件",
    "族群名稱": "族群名稱",
    "請輸入族群名稱": "請輸入族群名稱",
    "所屬族群": "所屬族群",
    "請選擇所屬族群": "請選擇所屬族群",
    "關閉": "關閉",
    "確定": "確定",
    "最少需要選擇幾位族群成員": "最少需要選擇 %s 位族群成員",
    "最少需要選擇幾位託管人員": "最少需要選擇 %s 位託管人員",
    "最多只能選擇幾位託管人員": "最多只能選擇 %s 位託管人員",
    "尚未異動項目": "尚未異動項目",
    "成員種類1": "族群成員",
    "成員種類2": "託管人員",
    "可選擇幾個會友": "可選擇 <b class=\"cgBase\">%s</b> 個會友",
    "請選擇加入的項目": "請選擇加入的項目",
    "加入": "加入",
    "移除": "移除",
    "已選擇幾位族群成員": "已選擇 <b class=\"cgBase\">%s</b> 位族群成員",
    "已選擇幾位託管人員": "已選擇 <b class=\"cgBase\">%s</b> 位託管人員",
    "相關資訊": "相關資訊",
    "基本:": "基本:",
    "團報": "團報",
    "表單": "表單",
    "金流": "金流",
    "報名人員": "報名人員",
    "報名時間": "報名時間",
    "重設": "重設",
    "立即更新": "立即更新",
    "聚會開始時間": "聚會開始時間",
    "請選擇開始時間": "請選擇開始時間",
    "首次聚會開始時間": "首次聚會開始時間",
    "聚會結束時間": "聚會結束時間",
    "請選擇結束時間": "請選擇結束時間",
    "首次聚會結束時間": "首次聚會結束時間",
}
